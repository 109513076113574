import React from 'react'
import styled from 'styled-components'

const NotFoundStyled = styled.div`
  /*  */
`

const NotFound = () => {
  return (
    <NotFoundStyled>
      <h1>NotFound</h1>
    </NotFoundStyled>
  )
}

export default NotFound
