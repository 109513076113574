export const itemsMenuAdmin = [
  // {
  //   id: '001',
  //   path: '/admin/usuarios',
  //   name: 'Usuarios',
  // },
  {
    id: '002',
    path: '/admin/productos',
    name: 'Productos',
  },
  {
    id: '003',
    path: '/admin/categorias',
    name: 'Categorias',
  },
]