import React from 'react'
import styled from 'styled-components'
import { WrapperAdmin } from '../../globalStyles'

const HomeAdminStyled = styled.div`
`

const HomeAdmin = () => {
  return (
    <HomeAdminStyled>
      <WrapperAdmin>
        <h1>Muy pronto se podra Observar analiticas e información sobre el uso de la Aplicación</h1>
      </WrapperAdmin>
    </HomeAdminStyled>
  )
}

export default HomeAdmin
